<script>
import { Doughnut } from "vue-chartjs";

import { eventBus } from "../main.js";

export default {
  extends: Doughnut,
  data() {
    return {
      unprocessed_labels: {},
      labels: ["No Data"],
      b_colors: ["#BDBDBD"],
      p: null,
      processed_data: [],
      selected_section: null,
      data_pie: {},
      options_pie: {
        title: {
          display: true,
          text: "Calf Crop",
        },
        responsive: true,
        onClick: this.select_animals,
        maintainAspectRatio: false,
        animation: {
          animateScale: false,
          animateRotate: false,
        },
        legend: { position: "left", onClick: null },
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              display: false,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    select_animals(p, event) {
      if (event[0] && this.labels[0] != "No Data") {
        if (this.selected_section == event[0]) {
          this.selected_section = event[0];
          this.selected_section._model.outerRadius -= 5;
          this.selected_section = false;
          eventBus.$emit("chart_query", false);
        } else {
          if (this.selected_section) {
            this.selected_section._model.outerRadius -= 5;
          }
          eventBus.$emit("clear_select", "cs");
          event[0]._model.outerRadius += 5;
          this.selected_section = event[0];
          eventBus.$emit("chart_query", ["calf_sex", this.unprocessed_labels[this.labels[event[0]._index]]]);
        }
      }
    },
    process_label(label) {
      var output_label = ""
      if (label.includes('_')) {
        let split_label = label.split('_')
        output_label = split_label[0] + ' ' + split_label[1].charAt(0) + split_label[1].slice(1).toLowerCase()
      } else {
        output_label = label.charAt(0) + label.slice(1).toLowerCase()
      }
      this.unprocessed_labels[output_label] =  label
      return output_label
    },    
    populate_chart() {
      if (
        this.calves_by_sex &&
        this.calves_by_sex.length
      ) {
        this.processed_data = [];
        this.labels = [];
        var temp_processed_data = [];
        for (const i in this.calves_by_sex) {
          if (
            this.calves_by_sex[i].sex &&
            this.calves_by_sex[i].sex != "UNDEFINED"
          ) {
            this.labels.push(
              this.process_label(this.calves_by_sex[i].sex)
            );
            temp_processed_data.push(this.calves_by_sex[i].count);
          } else {
            if (this.labels.indexOf("Undefined") != -1) {
              temp_processed_data[this.labels.indexOf("Undefined")] +=
                this.calves_by_sex[i].count;
            } else {
              this.labels.push(this.process_label("UNDEFINED"));
              temp_processed_data.push(this.calves_by_sex[i].count);
            }
          }
        }

        this.b_colors = [
          "#42A5F5",
          "#388E3C",
          "#F57C00",
          "#FBC02D",
          "#ae4773",
          "#093D72",
          "#00C7BE",
          "#388E3C",
          "#BDBDBD",
        ];

        this.b_colors[this.labels.indexOf('Undefined')] = "#BDBDBD"

        this.processed_data = temp_processed_data;
      } else {
        this.processed_data = [1]
      }
    },
  },
  computed: {
    calves_by_sex() {
      return this.$store.getters.getCalvesBySex;
    },
    chart_params() {
      return {
        data_pie: {
          datasets: [
            {
              data: this.processed_data,
              backgroundColor: this.b_colors,
              borderColor: this.$vuetify.theme.dark
                ? "rgba(255, 255, 255, 0.6)"
                : "#fff",
            },
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },

    theme() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {

  },
  created() {
    eventBus.$on("clear_select", (t) => {
      if (t != "cs") {
        if (this.selected_section) {
          this.renderChart(this.chart_params.data_pie, this.options_pie);
        }

        this.selected_section = false;
      }
    });
  },
  watch: {
    calves_by_sex() {
      this.populate_chart();
    },
    theme() {
      if (this.processed_data.length != 0) {
        this.renderChart(this.chart_params.data_pie, this.options_pie);
      }
    },
    processed_data() {
      if (this.processed_data.length != 0) {
        this.renderChart(this.chart_params.data_pie, this.options_pie);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>



