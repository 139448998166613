<script>
import { Bar } from "vue-chartjs";

import { eventBus } from "../main.js";

export default {
  extends: Bar,
  data() {
    return {
      labels: ["No Data"],
      b_colors: ["#BDBDBD"],
      p: null,
      processed_data: [],
      selected_section: null,
      data_pie: {},
      options_pie: {
        title: {
          display: true,
          text: "Mature Weight (kg)",
        },
        responsive: true,
        onClick: this.select_animals,
        maintainAspectRatio: false,
        animation: {
          animateScale: false,
          animateRotate: false,
        },
        legend: { position: "left", onClick: null, display: false },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: true,
              },
            },
            
          ],
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                precision:0
              },
              gridLines: {
                display: true,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    select_animals(p, event) {
      if (event[0] && this.labels[0] != "No Data") {
        if (this.selected_section == event[0]) {
          this.selected_section = event[0];
          this.selected_section._model.width -= 5;
          this.selected_section = false;
          eventBus.$emit("chart_query", false);
        } else {
          if (this.selected_section) {
            this.selected_section._model.width -= 5;
          }
          eventBus.$emit("clear_select", "mwm");
          event[0]._model.width += 5;
          this.selected_section = event[0];
          eventBus.$emit("chart_query", [
            "animals_weight_mature",
            this.labels[event[0]._index],
          ]);
        }
      }
    },

    populate_chart() {
      if (this.animals_by_weight_mature && this.animals_by_weight_mature.length) {
        this.processed_data = [];
        this.labels = [];
        var temp_processed_data = [];
        for (const i in this.animals_by_weight_mature) {
            this.labels.push(
            this.animals_by_weight_mature[i].weight_range
            );
            temp_processed_data.push(this.animals_by_weight_mature[i].animal_count);
        }

        this.b_colors = "#ae4773"

        this.processed_data = temp_processed_data;
      } else {
        this.processed_data = [1];
      }
    },
  },
  computed: {
    animals_by_weight_mature() {
      return this.$store.getters.getAnimalByWeightMature;
    },
    chart_params() {
      return {
        data_pie: {
          datasets: [
            {
              data: this.processed_data,
              backgroundColor: this.b_colors,
              borderColor: this.$vuetify.theme.dark
                ? "rgba(255, 255, 255, 0.6)"
                : "#fff",
            },
          ],

          // These labels appear in the legend and in the tooltips when hovering different arcs
          labels: this.labels,
        },
      };
    },

    theme() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {},
  created() {
    eventBus.$on("clear_select", (t) => {
      if (t != "mwm") {
        if (this.selected_section) {
          this.renderChart(this.chart_params.data_pie, this.options_pie);
        }

        this.selected_section = false;
      }
    });
  },
  watch: {
    animals_by_weight_mature() {
      this.populate_chart();
    },
    theme() {
      if (this.processed_data.length != 0) {
        this.renderChart(this.chart_params.data_pie, this.options_pie);
      }
    },
    processed_data() {
      if (this.processed_data.length != 0) {
        this.renderChart(this.chart_params.data_pie, this.options_pie);
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>



