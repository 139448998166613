<template>
  <div>
    <v-sheet tile height="38" class="d-flex calendar_controls">
      <v-btn
        x-small
        elevation="0"
        color="primary"
        fab
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>

      <div style="max-width: 140px" class="mb_hidden">
        <v-select
          class="assign_select ml-2"
          height="32"
          v-model="type"
          :items="types"
          dense
          outlined
          hide-details
          label="Timeframe"
        ></v-select>
      </div>
      <div style="max-width: 180px">
        <v-select
          class="assign_select ml-2"
          height="32"
          v-model="event_type"
          :items="[
            { value: 'ALL', text: 'All' },
            { value: 'ARRIVAL', text: 'Arrival' },
            { value: 'AUCTION', text: 'Auction' },
            { value: 'BREEDING', text: 'Breeding' },
            { value: 'CALVING', text: 'Calving' },
            { value: 'COMMUNITY_SUPPORT', text: 'Community Support' },
            { value: 'FERTILIZATION', text: 'Fertilization' },
            { value: 'HARVEST', text: 'Harvest' },
            { value: 'HAYING', text: 'Haying' },
            { value: 'HOLIDAY', text: 'Holiday' },
            { value: 'MEETING', text: 'Meeting' },
            { value: 'PLANTATION', text: 'Plantation' },
            { value: 'PREGNANCY_CHECK', text: 'Pregnancy Check' },
            { value: 'PROCESSING', text: 'Processing' },
            { value: 'REPAIR', text: 'Repair' },
            { value: 'SEEDING', text: 'Seeding' },
            { value: 'SHIPPING', text: 'Shipping' },
            { value: 'SOCIAL_EVENT', text: 'Social Event' },
            { value: 'VACATION', text: 'Vacation' },
            { value: 'VET_APPOINTMENT', text: 'Vet Appointment' },
            { value: 'OTHER', text: 'Other' },
          ]"
          dense
          outlined
          hide-details
          label="Event Type"
        ></v-select>
      </div>
      <v-menu
        v-model="filter_menu"
        :close-on-content-click="false"
        offset-overflow
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            height="32"
            v-bind="attrs"
            v-on="on"
            outlined
            color="grey"
          >
            <v-icon>mdi-transit-connection-horizontal</v-icon>
          </v-btn>
        </template>

        <v-card width="200px" height="140px">
          <v-row>
            <v-col cols="12">
              <v-select
                class="assign_select ml-2 mt-2 mr-2"
                height="32"
                v-model="association"
                :items="['Herd', 'Zone']"
                dense
                outlined
                hide-details
                label="Association"
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-if="association == 'Herd'"
                class="assign_select ml-2 mt-2 mr-2"
                height="32"
                v-model="association_filter"
                :items="herds"
                dense
                outlined
                hide-details
                label="Herd"
              ></v-select>
              <v-select
                v-if="association == 'Zone'"
                class="assign_select ml-2 mt-2 mr-2"
                height="32"
                v-model="association_filter"
                :items="zones"
                dense
                outlined
                hide-details
                label="Zone"
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-menu>
      <v-spacer />
      <div style="width: 200px" class="ml-1">
        <v-text-field
          class="assign_select assign_select_v mr-2"
          height="32"
          v-model="event_search"
          dense
          outlined
          hide-details
          label="Search"
        ></v-text-field>
      </div>

      <v-btn
        x-small
        elevation="0"
        color="primary"
        fab
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet class="calendar_sheet">
      <v-calendar
        :events="calendar_events"
        v-model="calendar_model"
        ref="calendar"
        :event-color="getEventColor"
        :type="type"
        @click:event="open_event"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import { eventBus } from "../main.js";
import moment from "moment";
export default {
  components: {},
  data() {
    return {
      filter_menu: false,
      event_filter: {
        event_type: "ALL",
        event_search: "",
        association: "Herd",
        association_filter: "ALL",
        date: moment().format("YYYY-MM"),
      },
      event_type: "ALL",
      event_search: "",
      association: "Herd",
      association_filter: "ALL",

      types: [
        { text: "Month", value: "month" },
        { text: "Week", value: "week" },
        { text: "Day", value: "day" },
      ],
      type: "month",
      calendar_model: moment().format("YYYY-MM-DD"),
      event_colors: {
        BLUE: "oc_blue",
        MINT: "oc_mint",
        GREEN: "oc_green",
        INDIGO: "oc_indigo",
        PURPLE: "oc_purple",
        PINK: "oc_pink",
        RED: "oc_red",
        ORANGE: "oc_orange",
        YELLOW: "oc_yellow",
        UNDEFINED: "oc_orange",
      },
    };
  },
  methods: {
    getEventColor(event) {
      return this.event_colors[event.color];
    },
    fetch_events() {
      console.log(this.calendar_model);
      this.$store.dispatch("DDB_GET_CALENDAR_EVENTS", this.event_filter);
    },
    open_event(e) {
      if (e.event.id.split("_")[1] == "notification") {
        this.$router.push({
          name: "notifications",
          params: {
            date_filter: e.event.id.split("_")[0],
          },
        });
      } else {
        eventBus.$emit("open_event", e.event.id);
      }
    },
  },

  computed: {
    event_types() {
      var zone_types = [];
      if (this.$store.getters.getEnums.event_type) {
        zone_types = this.$store.getters.getEnums.event_type;
        zone_types.unshift({ value: "ALL", text: "All" });
      }
      return zone_types;
    },
    calendar_events() {
      return this.$store.getters.getCalendarEvents;
    },

    zones() {
      var herds = [
        {
          text: "All",
          value: "ALL",
        },
      ];

      for (const i in this.$store.getters.getZones) {
        herds.push({
          text: this.$store.getters.getZones[i].name,
          value: this.$store.getters.getZones[i].id,
        });
      }

      return herds;
    },
    herds() {
      var herds_only = [
        {
          text: "All",
          value: "ALL",
        },
      ];
      for (const i in this.$store.getters.getHerds) {
        herds_only.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
        });
      }

      return herds_only;
    },
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
  },
  created() {
    if (this.customer_id) {
      this.fetch_events();
    }
    eventBus.$on("fetch_calendar_events", () => this.fetch_events());
  },
  watch: {
    customer_id() {
      this.fetch_events();
    },
    association_filter() {
      this.event_filter.association_filter = this.association_filter;
      this.fetch_events();
    },
    association() {
      this.event_filter.association = this.association;
      this.association_filter = "ALL";
    },
    event_type() {
      this.event_filter.event_type = this.event_type;
      this.fetch_events();
    },
    event_search() {
      this.event_filter.event_search = this.event_search;
      this.fetch_events();
    },
    calendar_model() {
      this.event_filter.date = this.calendar_model.slice(0, 7);
      this.fetch_events();
    },
  },
};
</script>
<style lang="css">
.calendar_sheet {
  height: calc(50vh - 37px);
}
</style>