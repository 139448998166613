<template>
  <v-row justify="space-around" class="pr-2 pl-2 mt-2">
    <v-col xl="6" lg="6" cols="12" class="max_width pa-1">
      <v-card elevation="2">
        <v-card-text class="pa-0" v-if="markers">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="position: absolute; z-index: 4; top: 8px; left: 8px"
                fab
                v-bind="attrs"
                class="mr-2"
                v-on="on"
                @click="show_cams = !show_cams"
                x-small
                :color="show_cams ? 'primary' : '#e0e0e0'"
                elevation="0"
              >
                <v-icon>mdi-cctv</v-icon></v-btn
              >
            </template>
            <span>Display Cameras</span>
          </v-tooltip>
          <!-- <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="position: absolute; z-index: 4; top: 8px; left: 48px"
                fab
                v-bind="attrs"
                class="mr-2"
                v-on="on"
                @click="show_jetsons = !show_jetsons"
                x-small
                :color="show_jetsons ? 'primary' : '#e0e0e0'"
                elevation="0"
              >
                <v-icon>mdi-router-wireless</v-icon></v-btn
              >
            </template>
            <span>Display Jetsons</span>
          </v-tooltip> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="position: absolute; z-index: 4; top: 8px; left: 48px"
                fab
                v-bind="attrs"
                class="mr-2"
                v-on="on"
                @click="show_wifis = !show_wifis"
                x-small
                :color="show_wifis ? 'primary' : '#e0e0e0'"
                elevation="0"
              >
                <v-icon>mdi-wifi</v-icon></v-btn
              >
            </template>
            <span>Display WIFIs</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="position: absolute; z-index: 4; top: 8px; left: 88px"
                fab
                v-bind="attrs"
                class="mr-2"
                v-on="on"
                @click="show_zones = !show_zones"
                x-small
                :color="show_zones ? 'primary' : '#e0e0e0'"
                elevation="0"
              >
                <v-icon>mdi-select-group</v-icon></v-btn
              >
            </template>
            <span>Display Zones</span>
          </v-tooltip>
          <v-spacer />
          <div
            style="
              width: 200px;
              position: absolute;
              z-index: 4;
              top: 8px;
              right: 60px;
              background-color: white;
              border-radius: 25px;
            "
          >
            <v-select
              hide-details
              dense
              class="assign_select font_400"
              outlined
              rounded
              height="32"
              style=""
              full-width
              v-if="sites[1]"
              v-model="chosen_site"
              :items="sites[1]"
              :color="show_zones ? 'primary' : '#e0e0e0'"
              label="Site"
            >
            </v-select>
          </div>
          <GmapMap
            v-if="1 + 1 == 2"
            :center="
              markers[0]
                ? {
                    lat: parseFloat(markers[0].latitude),
                    lng: parseFloat(markers[0].longitude),
                  }
                : {
                    lat: parseFloat(0),
                    lng: parseFloat(0),
                  }
            "
            :zoom="markers[2]"
            map-type-id="hybrid"
            style="width: inherit; height: 50vh"
            :options="{
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: true,
            }"
            ref="map2"
          >
            <div :key="index + m[0].position" v-for="(m, index) in markers[1]">
              <GmapMarker
                v-if="
                  (show_cams && m[2] == 'camera') ||
                  (show_jetsons && m[2] == 'jetson') ||
                  (show_wifis && m[2] == 'wifi')
                "
                :position="m[0].position"
                :clickable="true"
                :draggable="false"
                @click="nav_to_table(m[1], m[2], m[0])"
                @mouseover="set_marker(m[0], m[2])"
                @mouseout="set_marker(false, m[2])"
                :icon="m[0].icon"
                ><GmapInfoWindow
                  :opened="
                    open_marker[0] == m[0].title &&
                    open_marker[1] == 'camera' &&
                    map_distances.length == 0
                  "
                  ><h3>{{ m[0].title }}</h3>
                  <img
                    onerror="this.onerror=null; this.src='@/assets/no_media_light.png'"
                    height="120"
                    alt=""
                    :src="`${
                      cookie_bucket +
                      $store.getters.getUser.username +
                      '/thumbnails/' +
                      m[0].thumbnail
                    }`"
                  />
                </GmapInfoWindow>
                <GmapInfoWindow
                  :opened="
                    open_marker[0] == m[0].title && map_distances.length > 0
                  "
                >
                  <span class="distance_list_close"
                    ><h3>{{ m[0].title }}</h3>
                    <v-icon
                      @click="
                        (map_distances = []), (open_marker = [false, false])
                      "
                      >mdi-close</v-icon
                    ></span
                  >
                  <v-list v-if="map_distances" width="280" max-height="300">
                    <v-list-item
                      v-for="(camera, i) in map_distances"
                      :key="i"
                      class="pa-1 pb-0 pt-0 distance_list_item"
                    >
                      <v-list-item-icon class="mt-0 pt-1 mb-0 mr-4">
                        <v-icon :color="get_color(camera[0].color)"
                          >mdi-cctv</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content
                        class="pt-0 pb-4 distance_list_content"
                      >
                        <v-list-item-title class="map_sidebar_item_name">
                          <span class="distance_list_title">{{
                            camera[0].title
                          }}</span>
                          <span class="nav_arrows_f">
                            {{ camera[1] + " m" }}</span
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </GmapInfoWindow>
                <GmapInfoWindow
                  :opened="
                    open_marker[0] == m[0].title &&
                    open_marker[1] != 'camera' &&
                    map_distances.length == 0
                  "
                  ><h3>{{ m[0].title }}</h3>
                </GmapInfoWindow></GmapMarker
              >
            </div>
            <div v-if="show_zones">
              <GmapPolygon
              
                :key="z.index"
                v-for="z in zone_paths"
                :paths="z.perimeter"
                :options="{strokeColor: zone_colors[z['color']],
              fillColor: zone_colors[z['color']]}"
              >
              </GmapPolygon>
            </div>
          </GmapMap>
          <div v-else style="width: inherit; height: 50vh; position: relative">
            <v-progress-circular
              indeterminate
              color="primary"
              style="
                position: absolute;
                top: calc(50% - 100px);
                right: calc(50% - 100px);
              "
              size="200"
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col xl="6" lg="6" cols="12" class="max_width pa-1">
      <calendar />
    </v-col>

    <!-- <v-col cols="12" class="pa-1 pb-2">
      <v-row class="pr-2 pl-2">
        <v-col xl="3" lg="3" md="6" class="pa-1">
          <v-card>
            <v-card-title class="small_card_title"
              >Recent Uploads (Days)</v-card-title
            >
            <v-card-text class="home_chart_ct">
              <chart_summary_media
              :labels="recent_uploads_1day.labels"
            :datasets="recent_uploads_1day.datasets"
            :timeframe="'1day'"
            title="Recent Uploads (Days)"
              />
       
            </v-card-text>
          </v-card>
        </v-col>
        <v-col xl="3" lg="3" md="6" class="pa-1">
          <v-card>
            <v-card-title class="small_card_title"
              >Recent Uploads (Hours)</v-card-title
            >
            <v-card-text class="home_chart_ct">
              <chart_summary_media
              :labels="recent_uploads_1hour.labels"
            :datasets="recent_uploads_1hour.datasets"
            :timeframe="'1day'"
            title="Recent Uploads (Days)"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col xl="3" lg="3" md="6" class="pa-1">
          <v-card>
            <v-card-title class="small_card_title">Animals</v-card-title>
            <v-card-text class="home_chart_ct">
              <chartAnimalType v-if="load_ready_animals" />
              <v-list-item v-else>
                <v-list-item-title style="text-align: center; color: gray"
                  ><h4>No Animal Data</h4>
                </v-list-item-title>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col xl="3" lg="3" md="6" class="pa-1">
          <v-card>
            <v-card-title class="small_card_title">Camera Status</v-card-title>
            <v-card-text class="home_chart_ct">
              <chartStatus v-if="load_ready_cameras" />
              <v-list-item v-else>
                <v-list-item-title style="text-align: center; color: gray"
                  ><h4>No Camera Data</h4>
                </v-list-item-title>
              </v-list-item></v-card-text
            >
          </v-card>
        </v-col>
      </v-row>
    </v-col> -->
  </v-row>
</template>

<script>
import calendar from "@/components/Calendar.vue";
// import chartStatus from "@/components/chart_cam_status.vue";
// import chartAnimalType from "@/components/chart_animal_type.vue";
import moment from "moment/moment";
// import chart_summary_media from "./summary_charts/chart_summary_media.vue";


//import alertCard from "@/components/alert_card.vue";

export default {
  components: {
    calendar,
    // chart_summary_media,
    //mostRecentCard,

    // chartAnimalType,
    // chartStatus,
  },
  data() {
    return {
      customer: "All",
      filter_params: {},
      camera: "All",
      videos: [],
      customer_select: "All",
      open_marker: [false, false],
      map_distances: [],
      show_cams: true,
      show_jetsons: true,
      show_wifis: true,
      show_zones: true,
      zone_colors: {
        BLUE: "#007AFF",
        MINT: "#00C7BE",
        GREEN: "#34C759",
        INDIGO: "#5856D6",
        PURPLE: "#AF52DE",
        PINK: "#FF2D54",
        RED: "#FF3A30",
        ORANGE: "#FF9500",
        YELLOW: "#FFCC00",
        null: "#0090a4",
      },
      chosen_site: "0",
    };
  },
  methods: {
    process_summary_data(summary_data) {
      var labels = [];

      var inferred_media = [[], []];

      var already_added = false;

      for (const i in summary_data) {
        if (
          !labels.includes(
            moment(summary_data[i].media_timestamp).format("MM-DD HH:mm")
          )
        ) {
          labels.unshift(
            moment(summary_data[i].media_timestamp).format("MM-DD HH:mm")
          );
        } else {
          already_added = true;
        }

        let index = 12 - labels.length;

        if (index < 0) {
          labels.pop();
          break;
        }
        if (summary_data[i].media_type == "VIDEO") {
          inferred_media[0][index] = summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[1][index] = 0;
          }
        }
        if (summary_data[i].media_type == "IMAGE") {
          inferred_media[1][index] = summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[0][index] = 0;
          }
        }
      }

      var datasets = [
        {
          data: inferred_media[0],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: inferred_media[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Images",
        },
      ];
        
      return { datasets: datasets, labels: labels };
    },
    zoomout() {
      try {
        var bounds = this.$refs.map2.$mapObject.getBounds();
        for (const i in this.markers[1]) {
          bounds.extend(this.markers[1][i][0].position);
        }
        this.$refs.map2.fitBounds(bounds);
      } catch (e) {
        return e;
      }
    },
    get_color(color) {
      if (color) {
        if (color == "MINT") {
          return "#00C7BE";
        }
        return color.toLowerCase();
      }
      return "orange";
    },
    get_single_distance(w, c) {
      var p = 0.017453292519943295; // Math.PI / 180
      var mcos = Math.cos;
      var a =
        0.5 -
        mcos((c.lat - w.lat) * p) / 2 +
        (mcos(w.lat * p) * mcos(c.lat * p) * (1 - mcos((c.lat - w.lat) * p))) /
          2;

      return parseInt(12742000 * Math.asin(Math.sqrt(a))); // 2 * R; R = 6371 km
    },
    get_distances(obj, dt) {
      if (dt == "wifi") {
        var distance_array = [];

        for (const i in this.markers[1]) {
          if (this.markers[1][i][2] == "camera") {
            distance_array.push([
              this.markers[1][i][0],
              this.get_single_distance(
                obj.position,
                this.markers[1][i][0].position
              ),
            ]);
          }
        }
        distance_array.sort(
          (function (index) {
            return function (b, a) {
              return a[index] === b[index] ? 0 : a[index] < b[index] ? -1 : 1;
            };
          })(1)
        );
        this.map_distances = distance_array;
      }
    },
    set_marker(marker, dt) {
      if (marker) {
        this.open_marker = [marker.title, dt];
      } else {
        if (this.map_distances.length == 0) {
          this.open_marker = [false, false];
        }
      }
    },
    sortByNested(array, key) {
      key = key.split(".");
      var len = key.length;

      array.sort(function (a, b) {
        var i = 0;
        while (i < len) {
          a = a[key[i]];
          b = b[key[i]];
          i++;
        }
        if (a < b) {
          return -1;
        } else if (a > b) {
          return 1;
        } else {
          return 0;
        }
      });

      return array;
    },
    nav_to_table(device_id, device_type, device) {
      if (device_type == "camera") {
        this.$router.push({
          name: "videos",
          params: {
            cameraname: device_id.replaceAll(" ", "++"),
          },
        });
      } else if (device_type == "wifi") {
        this.get_distances(device, "wifi");
      }
    },
    shuffle(array) {
      var currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    },
  },
  watch: {
    camera() {
      if (this.camera != "All") {
        if (this.customer != "All") {
          this.filter_params.filter.camera_id = {
            eq: this.cameras[0][this.camera.split("  -  ")[0]],
          };
        } else {
          this.filter_params.filter = {
            camera_id: { eq: this.cameras[0][this.camera.split("  -  ")[0]] },
          };
        }
      } else {
        delete this.filter_params.filter.camera_id;
        if (this.customer == "All") {
          delete this.filter_params.filter;
        }
      }
    },

 
  },
  computed: {
    recent_uploads_1day() {
      return this.process_summary_data(
        this.$store.getters.getSummaryMediaMostRecent1Day
      );
    },
    recent_uploads_1hour() {
      return this.process_summary_data(
        this.$store.getters.getSummaryMediaMostRecent1Hour
      );
    },
    zone_paths() {
      var temp_zones = [];
      for (const i in this.$store.getters.getZones) {
        var temp_zone_path = [];
        for (const n in this.$store.getters.getZones[i].perimeter_lat) {
          temp_zone_path.push({
            lat: parseFloat(this.$store.getters.getZones[i].perimeter_lat[n]),
            lng: parseFloat(this.$store.getters.getZones[i].perimeter_long[n]),
          });
        }

        if (temp_zone_path.length) {
          temp_zones.push({
            perimeter: temp_zone_path,
            index: i,
            color: this.$store.getters.getZones[i].color,
          });
        }
      }
      return temp_zones;
    },
    customers() {
      var customers = [
        ...new Set(
          this.$store.getters.getCameraObjects.map(
            (item) => item.customer.customer_name
          )
        ),
      ];

      const sortAlphaNumCams = (a, b) =>
        a.localeCompare(b, "en", { numeric: true });
      customers.sort(sortAlphaNumCams);
      customers.unshift("All");

      return customers;
    },
    cookie_bucket() {
      return process.env.VUE_APP_COOKIE_BUCKET_FOOTAGE;
    },
    // load_ready_events() {
    //   return this.$store.getters.getCardEvents.length > 0 ? true : false;
    // },
    load_ready_animals() {
      return this.$store.getters.getAnimalByClassification.length > 0
        ? true
        : false;
    },
    load_ready_cameras() {
      return this.$store.getters.getCameraObjects.length > 0 ? true : false;
    },
    sites() {
      if (this.$store.getters.getSites.length) {
        let sites = [];
        for (const i in this.$store.getters.getSites) {
          sites.push({
            value: i,
            text: this.$store.getters.getSites[i].name,
          });
        }
        return [this.$store.getters.getSites, sites];
      }
      return false;
    },
    markers() {
      var markers = [];
      if (this.sites && this.sites.length) {
        for (const i in this.$store.getters.getCameraObjects) {
          if (
            this.$store.getters.getCameraObjects[i].coordinates_long &&
            this.$store.getters.getCameraObjects[i].coordinates_lat
          ) {
            var camera_icon = "ORANGE";
            if (
              this.$store.getters.getCameraObjects[i].map_icon_color &&
              this.$store.getters.getCameraObjects[i].map_icon_color !=
                "UNDEFINED"
            ) {
              camera_icon =
                this.$store.getters.getCameraObjects[i].map_icon_color;
            }

            markers.push([
              {
                position: {
                  lat: parseFloat(
                    this.$store.getters.getCameraObjects[i].coordinates_lat
                  ),
                  lng: parseFloat(
                    this.$store.getters.getCameraObjects[i].coordinates_long
                  ),
                },
                icon:
                  "https://map-icons-onecup.s3.us-west-2.amazonaws.com/camera_" +
                  camera_icon.toLowerCase() +
                  ".png",
                title: this.$store.getters.getCameraObjects[i].name,
                thumbnail:
                  this.$store.getters.getCameraObjects[i].id + "_latest.jpg",
                color: this.$store.getters.getCameraObjects[i].map_icon_color,
              },
              this.$store.getters.getCameraObjects[i].id,
              "camera",
            ]);
          }
        }

        for (const i in this.$store.getters.getWifis) {
          if (
            this.$store.getters.getWifis[i].coordinates_long &&
            this.$store.getters.getWifis[i].coordinates_lat
          ) {
            var wifi_icon = "ORANGE";
            if (
              this.$store.getters.getWifis[i].map_icon_color &&
              this.$store.getters.getWifis[i].map_icon_color != "UNDEFINED"
            ) {
              wifi_icon = this.$store.getters.getWifis[i].map_icon_color;
            }
            markers.push([
              {
                position: {
                  lat: parseFloat(
                    this.$store.getters.getWifis[i].coordinates_lat
                  ),
                  lng: parseFloat(
                    this.$store.getters.getWifis[i].coordinates_long
                  ),
                },
                icon:
                  "https://map-icons-onecup.s3.us-west-2.amazonaws.com/wifi_" +
                  wifi_icon.toLowerCase() +
                  ".png",
                title: "wifi " + String(parseInt(i) + parseInt(1)),
              },
              this.$store.getters.getWifis[i].id,
              "wifi",
            ]);
          }
        }

        var site_map_zoom_level =
          this.sites && this.sites[0][this.chosen_site].map_zoom_level
            ? this.sites[0][this.chosen_site].map_zoom_level
            : 2;
        markers = this.sortByNested([...markers], "0.position.lng");
        var site_location = this.sites
          ? {
              latitude: this.sites[0][this.chosen_site].coordinates_lat,
              longitude: this.sites[0][this.chosen_site].coordinates_long,
            }
          : { latitude: 45, longitude: 0 };
        return [site_location, markers, site_map_zoom_level];
      } else {
        return [{ latitude: 45, longitude: 0 }, markers, 2];
      }
    },
  },
  created() {
    //this.$store.dispatch("DDB_GET_ANIMAL_STATS");
    //this.$store.dispatch("DDB_GET_THUMBNAIL_INFO", { cameras: [], dates: [] });
  },
  mounted() {
    //    setTimeout(this.zoomout, 2000);
    //   var _this = this
    //  eventBus.$on("ready_hp_map", () => {setTimeout(_this.zoomout(), 3000)})
  },
};
</script>
<style scoped lang="css">
.small_card_title {
  font-size: 16px;
  text-align: center;
  display: inherit;
  padding: 4px;
}
.home_chart_ct {
  height: 27vh;
}
.home_chart_ct > div {
  height: 25vh;
}

#icon_info {
  font-size: 10px;
  margin-top: -4px;
  height: 20px;
  padding-left: 6px;
}
.select_customer {
  max-width: 240px;
}
.customer_title {
  float: right;
}
</style>
