<template>
  <div>
    <v-card-text class="pb-0">
      <v-row>
        <v-col xl="9" lg="8" md="7" sm="7">
          <v-row>
            <v-col cols="12" class="pl-0 pt-0 pb-1">
              <v-card>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col xl="6" lg="6" md="12" class="ch_col_lg pt-1">
                      <chartTreatmentHist
                    /></v-col>
                    <v-col xl="6" lg="6" md="12" class="ch_col_lg pt-1">
                      <chartTreatmentReason
                    /></v-col>
                    <v-col xl="6" lg="6" md="12" class="ch_col_lg pt-1">
                      <chartTreatmentType
                    /></v-col>
                    <v-col xl="6" lg="6" md="12" class="ch_col_lg pt-1">
                      <chartTreatmentDrug
                    /></v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          xl="3"
          lg="3"
          cols="12"
          class="pr-0"
          style="height: calc(75vh + 36px)"
          ><v-data-table
            style="height: calc(75vh + 24px); overflow-y: scroll"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="treatments"
            dense
            class="elevation-1"
            :items-per-page="20"
            hide-default-footer
            :server-items-length="treatment_count"
            :options.sync="options"
            :sort-by.sync="sort_by"
            :page.sync="page"
            :sort-desc.sync="sort_desc"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <div>
                <v-data-footer
                  class="dashboard_table_pagination"
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text=""
                  disable-items-per-page
                />
              </div>
            </template>
            <template v-slot:[`item.type`]="{ item }">
              {{ capitalizeFirstLetter(item.type) }}
            </template>
          </v-data-table></v-col
        >
      </v-row>
    </v-card-text>
  </div>
</template>


<script>
import chartTreatmentHist from "@/components/chart_treatment_hist.vue";
import chartTreatmentReason from "@/components/chart_treatment_reason.vue";
import chartTreatmentType from "@/components/chart_treatment_type.vue";
import chartTreatmentDrug from "@/components/chart_treatment_drug.vue";
import { eventBus } from "../main.js";

export default {
  components: {
    chartTreatmentHist,
    chartTreatmentReason,
    chartTreatmentType,
    chartTreatmentDrug,
  },
  data() {
    return {
      chart_query: false,
      options: {},
      sort_by: "date",
      page: 1,
      sort_desc: true,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      if (string) {
        if (
          string == "UNDEFINED" ||
          string == "Undefined" ||
          !string ||
          string == ""
        ) {
          return "-";
        }
        return (string.charAt(0) + string.slice(1).toLowerCase()).replaceAll(
          "_",
          " "
        );
      }
      return "";
    },
  },
  watch: {
    query_params: {
      handler() {
        if (this.query_params) {
        this.$store.dispatch("DDB_GET_TREATMENTS", this.query_params);
        }
      },
      deep: true,
    },
  },
  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    sort_direction() {
      return this.options.sortDesc && this.options.sortDesc[0] ? "desc" : "asc";
    },
    query_params() {
      if (this.customer_id) {
      return {
        herd: this.selected_herd,
        sort: this.options.sortBy ? this.options.sortBy[0] : "date",
        sort_direction: this.sort_direction,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        chart_query: this.chart_query,
      };} return undefined
    },
    treatments() {
      return this.$store.getters.getTreatments;
    },
    treatment_count() {
      return this.$store.getters.getTreatmentCount;
    },
    headers() {
      return [
        {
          text: "Date",
          value: "date",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },

        {
          text: "Type",
          value: "type",
          cellClass: this.editable ? "non-hl" : "no_edit",
        },
      ];
    },
  },
  created() {},
  mounted() {
    eventBus.$on("chart_query", (params) => {
      this.chart_query = params;
    });
  },
};
</script>
<style lang="css">
</style>
