<template>
  <div>
    <v-dialog v-model="expand_dialog" max-width="1000px">
      <animalCard
        :animal_id="selected_animal_id"
        :animal_index_pos="selected_animal_index_pos"
        :tab_index="0"
      />
    </v-dialog>
    <v-card-text class="pb-0">
      <v-row class="">
        <v-col cols="12" class="pa-0">
          <div style="width: 410px">
            <div style="width: 200px; float: left">
              <v-select
                class="assign_select"
                height="32"
                v-model="selected_herd"
                :items="herds"
                dense
                outlined
                rounded
                hide-details
                label="Herd"
              ></v-select>
            </div>
          </div>
        </v-col>
        <v-col xl="9" lg="8" md="12" sm="12">
          <v-row class="px-0">
            <v-col cols="12" class="pl-0 py-0">
              <v-card>
                <v-card-text class="py-0">
                  <v-row>
                    <v-col xl="4" lg="4" md="12" class="ch_col pt-1"
                      ><span class="title_span">Cows</span><ChartBreedingType
                    /></v-col>
                    <v-col xl="4" lg="4" md="12" class="ch_col"
                      ><ChartBreedingStatus
                    /></v-col>
                    <v-col xl="4" lg="4" md="12" class="ch_col"
                      ><ChartCowDeaths
                    /></v-col> </v-row></v-card-text
              ></v-card>
            </v-col>
            <v-col cols="12" class="pl-0 pb-0">
              <v-card
                ><v-card-text class="py-0"
                  ><v-row>
                    <v-col xl="4" lg="4" md="12" class="ch_col pt-1"
                      ><span class="title_span">Calves</span><ChartCalvingEase
                    /></v-col>
                    <v-col xl="4" lg="4" md="12" class="ch_col pt-1"
                      ><ChartCalfCrop
                    /></v-col>
                    <v-col xl="4" lg="4" md="12" class="ch_col"
                      ><ChartCalvesDeaths
                    /></v-col> </v-row></v-card-text
              ></v-card>
            </v-col>
            <v-col cols="12" class="pl-0 pb-0">
              <v-card
                ><v-card-text class="py-0"
                  ><v-row>
                    <v-col xl="4" lg="4" md="4" class="ch_col mb-n1 pt-1"
                      ><span class="title_span">Bulls</span
                      ><ChartScrotumCircumference /></v-col
                    ><v-col xl="4" lg="4" md="4" class="ch_col mb-n1 pt-1"
                      ><ChartSpermMorphology
                    /></v-col>
                    <v-col xl="4" lg="4" md="4" class="ch_col pt-1"
                      ><ChartSpermMotility
                    /></v-col> </v-row></v-card-text
              ></v-card>
            </v-col> </v-row
        ></v-col>
        <v-col
          class="pr-0"
          xl="3"
          lg="4"
          md="12"
          sm="12"
          style="height: calc(75vh + 36px)"
          ><v-data-table
            style="height: calc(75vh + 24px); overflow-y: scroll"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="animals"
            dense
            class="elevation-1"
            :items-per-page="20"
            hide-default-footer
            @click:row="select_animal"
            :server-items-length="animal_count"
            :options.sync="options"
            :sort-by.sync="sort_by"
            :page.sync="page"
            :sort-desc.sync="sort_desc"
          >
            <template v-slot:top="{ pagination, options, updateOptions }">
              <div>
                <v-data-footer
                  class="dashboard_table_pagination"
                  :pagination="pagination"
                  :options="options"
                  @update:options="updateOptions"
                  items-per-page-text=""
                  disable-items-per-page
                />
              </div>
            </template>
            <template v-slot:[`item.tag_label`]="{ item }">
              <v-chip
                style="
                  height: 24px;
                  margin-bottom: -2px;
                  margin-top: -3px;
                  padding-top: 2px;
                "
                v-if="item.tag_label"
                :color="
                  item.tag_color &&
                  item.tag_color != 'UNDEFINED'
                    ? 'oc_' +
                      item.tag_color.toLowerCase() +
                      ' darken-1'
                    : 'grey darken-1'
                "
                ><span style="color: white; font-weight: 500">{{
                  item.tag_label
                }}</span></v-chip
              ><span v-else>-</span>
            </template>
            <template v-slot:[`item.classification`]="{ item }">
              {{ capitalizeFirstLetter(item.classification) }}
            </template>
            <template
              v-slot:[`item.animal_breeding_history[0].type`]="{ item }"
            >
              {{
                item.animal_breeding_history[0]
                  ? capitalizeFirstLetter(
                      item.animal_breeding_history[0].type
                    ).replaceAll("_", " ")
                  : "-"
              }}
            </template>
            <template v-slot:[`item.animal_dam_status`]="{ item }">
              {{
                capitalizeFirstLetter(item.animal_dam_status).replaceAll(
                  "_",
                  " "
                )
              }}
            </template>
            <template v-slot:[`item.animal_birth_ease`]="{ item }">
              {{
                capitalizeFirstLetter(item.animal_birth_ease).replaceAll(
                  "_",
                  " "
                )
              }}
            </template>
            <template
              v-slot:[`item.animal_breeding_soundness[0].result`]="{ item }"
            >
              {{
                item.animal_breeding_soundness[0]
                  ? capitalizeFirstLetter(
                      item.animal_breeding_soundness[0].result
                    ).replaceAll("_", " ")
                  : "-"
              }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import animalCard from "@/components/AnimalCard.vue";

import ChartCalfCrop from "./chart_calf_crop.vue";
import ChartBreedingType from "./chart_breeding_type.vue";
import ChartBreedingStatus from "./chart_breeding_status.vue";
import ChartCalvingEase from "./chart_calving_ease.vue";
import ChartScrotumCircumference from "./chart_scrotum_circumference.vue";
import ChartSpermMorphology from "./chart_sperm_morphology.vue";
import ChartSpermMotility from "./chart_sperm_motility.vue";
import ChartCowDeaths from "./chart_cow_deaths.vue";
import ChartCalvesDeaths from "./chart_calves_deaths.vue";

import { eventBus } from "../main.js";

export default {
  components: {
    ChartCalfCrop,
    ChartBreedingType,
    ChartBreedingStatus,
    ChartCalvingEase,
    ChartScrotumCircumference,
    ChartSpermMorphology,
    ChartSpermMotility,
    ChartCowDeaths,
    ChartCalvesDeaths,
    animalCard,
    // chartJetsonStatus,
  },
  data() {
    return {
      selected_sub_window: 0,
      selected_herd: "all",
      expand_dialog: false,
      selected_animal_id: null,
      selected_animal_index_pos: null,
      selected_animal_index: null,
      chart_query: false,
      options: {},
      sort_by: "tag_label",
      page: 1,
      sort_desc: false,
    };
  },
  methods: {
    select_animal(item) {
      if (item) {
        this.selected_animal_id = item.id;
        this.selected_animal_index = this.animals.indexOf(item);
        if (this.selected_animal_index == 0) {
          this.selected_animal_index_pos = "first";
        } else if (this.selected_animal_index == this.animals.length - 1) {
          this.selected_animal_index_pos = "last";
        } else {
          this.selected_animal_index_pos = "m";
        }

        this.expand_dialog = true;
      }
    },
    capitalizeFirstLetter(string) {
      if (string) {
        if (
          string == "UNDEFINED" ||
          string == "Undefined" ||
          !string ||
          string == ""
        ) {
          return "-";
        }
        return string.charAt(0) + string.slice(1).toLowerCase();
      }
      return "";
    },
  },
  watch: {
    query_params: {
      handler() {
        if (this.query_params) {
           this.$store.dispatch("DDB_GET_ANIMALS", this.query_params);
        }
       
      },
      deep: true,
    },

    expand_dialog() {
      if (!this.expand_dialog) {
        this.selected_animal_id = null;
        this.selected_animal_index_pos = null;
        this.selected_animal_index = null;
      }
    },
  },
  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID;
    },
    sort_direction() {
      return (this.options.sortDesc && this.options.sortDesc[0])
        ? "desc"
        : "asc";
    },
    query_params() {
      if (this.customer_id) {
         return {
        herd: this.selected_herd,
        sort: this.options.sortBy ? this.options.sortBy[0] : "tag_label",
        sort_direction: this.sort_direction,
        limit: this.options.itemsPerPage,
        offset: (this.options.page - 1) * this.options.itemsPerPage,
        chart_query: this.chart_query,
      };
      } return undefined
     
    },
    animals() {
      return this.$store.getters.getAnimals;
    },
    animal_count() {
      return this.$store.getters.getAnimalCount;
    },
    headers() {
      var header = [
        {
          text: "Tag",
          value: "tag_label",
        },
        {
          text: "Birthday",
          value: "dob",
        },
        {
          text: "Classification",
          value: "classification",
        },
      ];
      return header;
    },
    herds() {
      var herds = [{ value: "all", text: "All" }];

      for (const i in this.$store.getters.getHerds) {
        herds.push({
          text: this.$store.getters.getHerds[i].name,
          value: this.$store.getters.getHerds[i].id,
        });
      }
      return herds;
    },
  },
  created() {
    eventBus.$on("change_animal", (dir) => {
      if (dir == 0) {
        this.select_animal(this.animals[this.selected_animal_index - 1]);
      } else if (dir == 1) {
        this.select_animal(this.animals[this.selected_animal_index + 1]);
      } else {
        this.select_animal(dir);
      }
    });
    eventBus.$on("chart_query", (params) => {
      this.chart_query = params;
    });
  },
  mounted() {},
};
</script>
<style lang="css">
</style>
