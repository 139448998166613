<template>
  <div>
    <v-card class="pl-3 pr-3 mt-3">
      <v-row>
        <v-col cols="12" class="pa-0">
          <v-tabs v-model="timeframe_tab" class="table_tabs_summary_dashboard ml-3 mb-3 mt-3">
            <v-tab>1 Day</v-tab>
            <v-tab>1 Hour</v-tab>
            <v-tab>5 Minutes</v-tab>
          </v-tabs>
          <span class="mr-2" style="width: 60px; display: block; float: right"
            ><v-select v-model="limit" :items="[10, 20, 40]"
          /></span>
        </v-col>
        <v-row class="ma-0 pa-0" v-if="media_summary_data.length">
          <v-col cols="3" class="bar_col">
            <chart_summary_media
              :labels="labels"
              :datasets="total_detected_objects"
              :timeframe="timeframe"
              title="Total Detected Objects"
            />
          </v-col>
          <v-col cols="3" class="bar_col">
            <chart_summary_media
              :labels="labels"
              :datasets="inferred_media"
              :timeframe="timeframe"
              title="Inferred Media"
            />
          </v-col>
          <v-col cols="3" class="bar_col">
            <chart_summary_media
              :labels="labels"
              :datasets="detected_objects_per_media"
              :timeframe="timeframe"
              title="Detected Objects Per Media"
            />
          </v-col>
          <v-col cols="3" class="pi_col">
            <chart_pi_summary_media
              :labels="['Videos', 'Images']"
              :datasets="inferred_media_pie"
              :timeframe="timeframe"
              title="Inferred Media by Type"
            />
          </v-col>
        </v-row>

        <v-col v-else> No Media Inferred for this customer </v-col>
      </v-row>
    </v-card>
    <v-card class="pl-3 pr-3 mt-3" v-if="media_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="pgie_labels"
            :datasets="total_animals_detected"
            :timeframe="timeframe"
            title="Detected Animals"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="pgie_labels"
            :datasets="total_people_detected"
            :timeframe="timeframe"
            title="Detected People"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="pgie_labels"
            :datasets="total_vehicles_detected"
            :timeframe="timeframe"
            title="Detected Vehicles"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Animals', 'People', 'Vehicles']"
            :datasets="detected_objects_doughnut"
            :timeframe="timeframe"
            title="Detections by Type"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pl-3 pr-3 mt-3" v-if="activity_summary_data.length">
      <v-row>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="activity_count"
            :timeframe="timeframe"
            title="Activity Count"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="[
              'Standing/Walking',
              'Swimming',
              'Sleeping',
              'Running',
              'Lying Down',
              'Playing',
              'Digging',
              'Climbing Stairs',
            ]"
            :datasets="activity_pie"
            :timeframe="timeframe"
            title="Detections by Activity"
          />
        </v-col>
        <v-col cols="3" class="bar_col">
          <chart_summary_media
            :labels="activity_labels"
            :datasets="age_count"
            :timeframe="timeframe"
            title="Age Count"
          />
        </v-col>
        <v-col cols="3" class="pi_col">
          <chart_doughnut_summary_media
            :labels="['Adult', 'Calf']"
            :datasets="age_pie"
            :timeframe="timeframe"
            title="Detections by Age"
          />
        </v-col>
      </v-row>
    </v-card>

  </div>
</template>

<script>
import chart_summary_media from "./summary_media/chart_summary_media.vue";
import chart_pi_summary_media from "./summary_media/chart_pi_summary_media.vue";
import chart_doughnut_summary_media from "./summary_media/chart_doughnut_summary_media.vue";
import moment from "moment";

export default {
  components: {
    chart_summary_media,
    chart_pi_summary_media,
    chart_doughnut_summary_media,
  },
  data() {
    return {
      timeframe_tab: 0,
      labels: [],
      pgie_labels: [],
      species_labels: [],
      activity_labels: [],
      calving_labels: [],
      estrus_labels: [],
      limit: 10,

      total_detected_objects: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Objects",
        },
      ],
      inferred_media: [
        {
          data: [],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: [],
          backgroundColor: "#388E3C2",
          borderColor: "#fff",
          label: "Images",
        },
      ],
      inferred_media_pie: [0, 0],

      total_animals_detected: [
        {
          data: [],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Detected Animals",
        },
      ],
      total_people_detected: [
        {
          data: [],
          backgroundColor: "#F57C00",
          borderColor: "#fff",
          label: "Detected People",
        },
      ],
      total_vehicles_detected: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Vehicles",
        },
      ],
      detected_objects_doughnut: [0, 0, 0],

      activity_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Walking",
        },
      ],

      activity_pie: [0, 0],

      age_count: [
        {
          data: [],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Adult",
        },
      ],
      age_pie: [0, 0],

    };
  },

  created() {
    this.fetch_analytics();
  },
  methods: {
    fetch_analytics() {
      this.$store.dispatch("DDB_GET_SUMMARY_MEDIA", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer_id,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_PGIE", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer_id,
      });
      this.$store.dispatch("DDB_GET_SUMMARY_ACTIVITY", {
        timeframe: this.timeframe,
        limit: this.limit,
        customer: this.customer_id,
      });
    },
  },
  watch: {
    timeframe() {
      this.fetch_analytics();
    },
    limit() {
      this.fetch_analytics();
    },

    media_summary_data() {
      var labels = [];

      var inferred_media = [[], []];
      var inferred_media_pie = [0, 0];

      var already_added = false;

      for (const i in this.media_summary_data) {
        if (
          !labels.includes(
            moment(this.media_summary_data[i].media_timestamp).format(
              "MM-DD HH:mm"
            )
          )
        ) {
          labels.unshift(
            moment(this.media_summary_data[i].media_timestamp).format(
              "MM-DD HH:mm"
            )
          );
        } else {
          already_added = true;
        }

        let index = this.limit - labels.length;

        if (index < 0) {
          labels.pop();
          break;
        }
        if (this.media_summary_data[i].media_type == "VIDEO") {
          inferred_media[0][index] =
            this.media_summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[1][index] = 0;
          }

          inferred_media_pie[0] +=
            this.media_summary_data[i].num_frames_inferred;
        }
        if (this.media_summary_data[i].media_type == "IMAGE") {
          inferred_media[1][index] =
            this.media_summary_data[i].num_frames_inferred;

          if (!already_added) {
            inferred_media[0][index] = 0;
          }

          inferred_media_pie[1] +=
            this.media_summary_data[i].num_frames_inferred;
        }
      }

      this.labels = labels;

      this.inferred_media = [
        {
          data: inferred_media[0],
          backgroundColor: "#FBC02D",
          borderColor: "#fff",
          label: "Videos",
        },
        {
          data: inferred_media[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Images",
        },
      ];

      this.inferred_media_pie = inferred_media_pie;
    },

    pgie_summary_data() {
      var labels = [];

      var total_detected_objects = [];

      var total_animals_detected = [];
      var total_people_detected = [];
      var total_vehicles_detected = [];
      var detected_objects_doughnut = [0, 0, 0];

      var mean_class_confidence = [[], [], []];
      var std_class_confidence = [[], [], []];
      var mean_bbox_area = [[], [], []];
      var mean_bbox_ratio = [[], [], []];

      for (const i in this.pgie_summary_data) {
        labels.unshift(
          moment(this.pgie_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        total_detected_objects.unshift(
          this.pgie_summary_data[i].animal_count +
            this.pgie_summary_data[i].person_count +
            this.pgie_summary_data[i].vehicle_count
        );

        total_animals_detected.unshift(this.pgie_summary_data[i].animal_count);
        total_people_detected.unshift(this.pgie_summary_data[i].person_count);
        total_vehicles_detected.unshift(
          this.pgie_summary_data[i].vehicle_count
        );

        detected_objects_doughnut[0] += this.pgie_summary_data[i].animal_count;
        detected_objects_doughnut[1] += this.pgie_summary_data[i].person_count;
        detected_objects_doughnut[2] += this.pgie_summary_data[i].vehicle_count;

        mean_class_confidence[0].unshift(
          this.pgie_summary_data[i].animal_class_conf_mean
        );
        mean_class_confidence[1].unshift(
          this.pgie_summary_data[i].person_class_conf_mean
        );
        mean_class_confidence[2].unshift(
          this.pgie_summary_data[i].vehicle_class_conf_mean
        );

        std_class_confidence[0].unshift(
          this.pgie_summary_data[i].animal_class_conf_stddev
        );
        std_class_confidence[1].unshift(
          this.pgie_summary_data[i].person_class_conf_stddev
        );
        std_class_confidence[2].unshift(
          this.pgie_summary_data[i].vehicle_class_conf_stddev
        );

        mean_bbox_area[0].unshift(
          this.pgie_summary_data[i].animal_bbox_area_mean
        );
        mean_bbox_area[1].unshift(
          this.pgie_summary_data[i].person_bbox_area_mean
        );
        mean_bbox_area[2].unshift(
          this.pgie_summary_data[i].vehicle_bbox_area_mean
        );

        mean_bbox_ratio[0].unshift(
          this.pgie_summary_data[i].animal_bbox_ratio_mean
        );
        mean_bbox_ratio[1].unshift(
          this.pgie_summary_data[i].person_bbox_ratio_mean
        );
        mean_bbox_ratio[2].unshift(
          this.pgie_summary_data[i].vehicle_bbox_ratio_mean
        );
      }

      this.pgie_labels = labels;

      this.total_detected_objects = [
        {
          data: total_detected_objects,
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Detected Objects",
        },
      ];

      (this.total_animals_detected = [
        {
          data: total_animals_detected,
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Animals",
        },
      ]),
        (this.total_people_detected = [
          {
            data: total_people_detected,
            backgroundColor: "#F57C00",
            borderColor: "#fff",
            label: "People",
          },
        ]),
        (this.total_vehicles_detected = [
          {
            data: total_vehicles_detected,
            backgroundColor: "#42A5F5",
            borderColor: "#fff",
            label: "Vehicles",
          },
        ]);

      this.detected_objects_doughnut = detected_objects_doughnut;

      this.mean_class_confidence = [
        {
          data: mean_class_confidence[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: mean_class_confidence[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: mean_class_confidence[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];

      this.std_class_confidence = [
        {
          data: std_class_confidence[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: std_class_confidence[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: std_class_confidence[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];

      this.mean_bbox_area = [
        {
          data: mean_bbox_area[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: mean_bbox_area[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: mean_bbox_area[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];

      this.mean_bbox_ratio = [
        {
          data: mean_bbox_ratio[0],
          borderColor: "#388E3C",
          label: "Animals",
          fill: false,
        },
        {
          data: mean_bbox_ratio[1],
          borderColor: "#F57C00",
          label: "People",
          fill: false,
        },
        {
          data: mean_bbox_ratio[2],
          borderColor: "#42A5F5",
          label: "Vehicles",
          fill: false,
        },
      ];
    },

    activity_summary_data() {
      var labels = [];

      var activity_count = [[], []];
      var activity_mean_confidence = [[], []];
      var activity_std_confidence = [[], []];
      var activity_pie = [0, 0, 0, 0, 0, 0, 0, 0, 0];

      var age_count = [[], []];
      var age_mean_confidence = [[], []];
      var age_std_confidence = [[], []];
      var age_pie = [0, 0];

      for (const i in this.activity_summary_data) {
        labels.unshift(
          moment(this.activity_summary_data[i].media_timestamp).format(
            "MM-DD HH:mm"
          )
        );

        activity_count[0].unshift(this.activity_summary_data[i].standing_walking_count);
        activity_count[1].unshift(
           this.activity_summary_data[i].lying_down_count
        );


        activity_mean_confidence[0].unshift(
          this.activity_summary_data[i].standing_walking_conf_mean
        );
        activity_mean_confidence[1].unshift(
          this.activity_summary_data[i].lying_down_conf_mean
        );

        activity_std_confidence[0].unshift(
          this.activity_summary_data[i].standing_walking_conf_stddev
        );
        activity_std_confidence[1].unshift(
          this.activity_summary_data[i].lying_down_conf_stddev
        );


        activity_pie[0] += this.activity_summary_data[i].standing_walking_count;
        activity_pie[1] += this.activity_summary_data[i].swimming_count;
        activity_pie[2] += this.activity_summary_data[i].sleeping_count;
        activity_pie[3] += this.activity_summary_data[i].running_count;
        activity_pie[4] += this.activity_summary_data[i].lying_down_count;
        activity_pie[5] += this.activity_summary_data[i].playing_count;
        activity_pie[6] += this.activity_summary_data[i].digging_count;
        activity_pie[7] += this.activity_summary_data[i].climbing_stairs_count;

        age_count[0].unshift(this.activity_summary_data[i].adult_count);
        age_count[1].unshift(this.activity_summary_data[i].calf_count);

        age_mean_confidence[0].unshift(
          this.activity_summary_data[i].adult_conf_mean
        );
        age_mean_confidence[1].unshift(
          this.activity_summary_data[i].calf_conf_mean
        );

        age_std_confidence[0].unshift(
          this.activity_summary_data[i].adult_conf_stddev
        );
        age_std_confidence[1].unshift(
          this.activity_summary_data[i].calf_conf_stddev
        );

        age_pie[0] += this.activity_summary_data[i].adult_count;
        age_pie[1] += this.activity_summary_data[i].calf_count;
      }

      this.activity_labels = labels;

      this.activity_count = [
        {
          data: activity_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Standing/Walking",
        },
        {
          data: activity_count[1],
          backgroundColor: "#093D72",
          borderColor: "#fff",
          label: "Lying Down",
        },
      ];
      this.activity_mean_confidence = [
        {
          data: activity_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Standing/Walking",
        },
        {
          data: activity_mean_confidence[1],
          borderColor: "#093D72",
          fill: false,
          label: "Lying Down",
        },
      ];
      this.activity_std_confidence = [
        {
          data: activity_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Standing/Walking",
        },
        {
          data: activity_std_confidence[1],
          borderColor: "#093D72",
          fill: false,
          label: "Lying Down",
        },
      ];
      this.activity_pie = activity_pie;

      this.age_count = [
        {
          data: age_count[0],
          backgroundColor: "#42A5F5",
          borderColor: "#fff",
          label: "Adult",
        },
        {
          data: age_count[1],
          backgroundColor: "#388E3C",
          borderColor: "#fff",
          label: "Calf",
        },
      ];
      this.age_mean_confidence = [
        {
          data: age_mean_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Adult",
        },
        {
          data: age_mean_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Calf",
        },
      ];
      this.age_std_confidence = [
        {
          data: age_std_confidence[0],
          borderColor: "#42A5F5",
          fill: false,
          label: "Adult",
        },
        {
          data: age_std_confidence[1],
          borderColor: "#388E3C",
          fill: false,
          label: "Calf",
        },
      ];
      this.age_pie = age_pie;
    },

  },
  computed: {
    customer_id() {
      return this.$store.getters.getCustomerID
    },
    timeframe() {
      if (this.timeframe_tab == 0) {
        return "1day";
      } else if (this.timeframe_tab == 1) {
        return "1hour";
      }
      if (this.timeframe_tab == 2) {
        return "5min";
      }
      return "1day";
    },
    media_summary_data() {
      return this.$store.getters.getSummaryMedia;
    },
    pgie_summary_data() {
      return this.$store.getters.getSummaryPGIE;
    },
    activity_summary_data() {
      return this.$store.getters.getSummaryActivity;
    },

    detected_objects_per_media() {
      var objects_per_media = [];
      if (
        this.total_detected_objects[0].data.length &&
        this.inferred_media[0].data.length
      ) {
        for (const i in this.total_detected_objects[0].data) {
          objects_per_media[i] =
            this.total_detected_objects[0].data[i] /
            (this.inferred_media[0].data[i] + this.inferred_media[1].data[i]);
        }
      }

      return [
        {
          data: objects_per_media,
          backgroundColor: "#ae4773",
          borderColor: "#fff",
          label: "Objects",
        },
      ];
    },
  },
};
</script>
<style scoped>
.pi_col > div {
  height: calc(100% - 14px);
  position: relative;
}
.bar_col > div {
  height: 300px;
  position: relative;
}
</style>